import React, {Component} from 'react';

import appContext from '../context/AppContext';
import {Product, ProductSet} from '../api/API';
import {Cart} from '../cart/Cart';

import CartEntry from '../CartEntry';

import {DataContextAPI, DataContextConsumer} from '../data/DataContext';
import PriceCalculator from '../common/PriceCalculator';

import './Modal.css';
import './CartModal.css';

class CartModal extends Component {

	render() {
		return <DataContextConsumer children={(ctx) => {
			const {items, entries} = this.getData(ctx);

			return <PriceCalculator playerName={''} promoCode={''} items={items} products={ctx.products} children={price => {
				return <div className="modal cart-modal">
					<button className="close" onClick={() => appContext.setCartOpened(false)}/>
		
					<h1>Корзина</h1>
		
					<hr/>
		
					<div className="controls">
						<button className="buy" disabled={items.length === 0} onClick={() => {
							appContext.setCartOpened(false);

							appContext.setPurchasedProducts(items);
						}}>
							Далее{price <= 0 ? "" : " (" + price + " ₽)"}
						</button>
					</div>

					{entries.length === 0 ? <div className="empty-cart">В корзине нет товаров</div> : entries}
					
					<hr className="last"/>
				</div>;
			}}/>;
		}}/>;
	}

	private getData(ctx: DataContextAPI) {
		let items = Cart.getItems();

		let entries: React.ReactElement[] = [];

		for (let i = 0; i < items.length; i++) {
			const item = items[i];

			const data = item.setId !== undefined ? ctx.sets[item.setId] : ctx.products[item.productId!];

			if (data === undefined) {
				// Product or set not found
				Cart.removeAllCartItems();

				return {items: [], entries: []};
			}

			try {
				if (data.isProduct()) {
					(data as Product).getPrice(item.setting, item.multiplier, ctx.wholesaleDiscounts);
				} else {
					(data as ProductSet).getPrice(ctx.products);
				}
			} catch (e) {
				// Invalid entry
				Cart.removeAllCartItems();

				return {items: [], entries: []};
			}

			entries.push(<CartEntry key={i} index={i} item={item} data={data} layout='DESKTOP' onItemsUpdate={() => this.setState({})}/>);
		}

		return {items: items, entries: entries};
	}
}

export default CartModal;