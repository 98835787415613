
import React from 'react';

import {Link} from "react-router-dom";

import './Footer.css';

const Footer = () => <footer>
	<div>Copyright &copy; Prostocraft 2024. Все права защищены. Сервер Prostocraft не относится к Mojang Studios.</div>
	<div>Для получения дополнительной информации и помощи обратитесь по адресу <a href="mailto:help@prostocraft.ru">help@prostocraft.ru</a></div>
	<div>ИП Калинин Илья Сергеевич (ИНН 272210678597 ОРНИП 321272400006253)</div>
	<div>
		<Link to="/offerAgreement">Договор-оферта</Link>{' | '}
		<Link to="/privacy">Политика обработки персональных данных</Link>{' | '}
		<Link to="/paymentProcess">Порядок проведения оплаты и безопасность операций</Link>
	</div>
	<div className="icons">
		<img src="six-plus.png" height="40px" alt="6+"/>
		<img src="sbp-footer.png" height="30px" alt="SBP"/>
	</div>
</footer>;

export default Footer;