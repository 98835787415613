import React, { Component } from "react";

import { Link } from "react-router-dom";

import { jukeboxWebSocketHost } from "../api/API";

import '../GamePage.css';
import './Player.css';

const notOnServer = 'Вы не на сервере!';
const waitingForStart = 'Ожидание старта игры';
const started = 'Поехали!';

const notOnServerDesc = 'Ожидание входа на сервер... Готовьтесь танцевать!';
const onArenaDesc = (arena: string) => 'Вы находитесь на арене ' + arena;

let globalInteracted = false;

class Player extends Component {

	private socket: WebSocket | null = null;
	private task: number = -1;

	private start = new Audio('/jukebox/round_start.mp3');
	private end = new Audio('/jukebox/round_end.mp3');
	private audio: HTMLAudioElement | null = null;

	private unmounted = false;

	state = {
		interacted: globalInteracted,
		volume: 1.0,
		color: 'light_gray',
		status: notOnServer,
		statusDesc: notOnServerDesc
	}

	componentDidMount() {
		this.connect();
	}

	private	connect() {
		this.socket = new WebSocket(jukeboxWebSocketHost + "/api/blockParty/jukebox");

		console.log('Connecting to', this.socket.url);

		this.socket.onopen = function () {
			console.log('Connected');
		};

		this.socket.onclose = event => {
			if (this.unmounted) {
				return;
			}

			console.log('Disconnected, code', event.code, 'reason', event.reason);

			this.setState({status: notOnServer, statusDesc: notOnServerDesc});

			this.clearAudio();
			
			this.scheduleReconnect();
		};

		this.socket.onerror = error => {
			if (this.unmounted) {
				return;
			}

			console.log('WS error', error);

			this.setState({status: notOnServer, statusDesc: notOnServerDesc});

			this.clearAudio();

			this.scheduleReconnect();
		};

		this.socket.onmessage = event => {
			if (this.unmounted) {
				return;
			}

			const msg = JSON.parse(event.data);

			console.log('>', event.data);

			switch (msg.method) {
				case "loadTrack":
					this.createAudio(msg.name);
					break;
				case "startTrack":
					this.ensureAudioSet(msg.name);
					this.audio!.play();

					this.setState({status: started, statusDesc: msg.name});

					break;
				case "pauseTrack":
					this.end.play();
					this.ensureAudioSet(msg.name);
					this.audio!.pause();
					break;
				case "resumeTrack":
					this.start.play();
					this.ensureAudioSet(msg.name);
					this.audio!.play();
					break;
				case "stopTrack":
					this.clearAudio();
					break;
				case "setColor":
					this.setState({color: msg.color});
					break;
				case "setArenaId":
					this.setState({status: waitingForStart, statusDesc: onArenaDesc(msg.id)});
					break;	
				case "clearArenaId":
					this.setState({status: notOnServer, statusDesc: notOnServerDesc});
					break;	
			}
		};
	}

	private scheduleReconnect() {
		console.log('Scheduling reconnect');
		this.cancelTask();
		this.task = setTimeout(() => this.connect(), 500) as any;
	}

	private cancelTask() {
		if (this.task !== -1) {
			console.log('Cancelling task', this.task);
			clearInterval(this.task);
			this.task = -1;
		}
	}

	private clearAudio() {
		if (this.audio !== null) {
			console.log('Clearing audio');
			this.audio.pause();
			this.audio = null;
		}
	}

	private createAudio(name: string) {
		this.clearAudio();
		this.audio = new Audio('/jukebox/' + name + '.mp3');
		this.audio.loop = true;
		this.audio.volume = this.state.volume;
	}

	private ensureAudioSet(name: string) {
		if (this.audio === null) {
			this.createAudio(name);
		}
	}

	componentWillUnmount() {
		if (this.socket !== null) {
			this.socket.close();
		}

		this.clearAudio();

		this.unmounted = true;
	}

	render() {
		if (!this.state.interacted) {
			return <div className="game-page">
				<Link className="back-to-games" to="/game"/>

				<div className="info">
					<div className="tabs">
						<Link to="/game/blockparty">Описание</Link>
						<button className="selected">Музыка</button>
					</div>
					<div className="info-text">
						<div className="interact-button">
							<button className="interact-button" onClick={() => {
								globalInteracted = true;

								this.setState({interacted: true});
							}}>Нажми, чтобы включить плеер!</button>
						</div>
					</div>
				</div>
			</div>;
		}

		return <div className="game-page">
			<Link className="back-to-games" to="/game"/>

			<div className="info">
				<div className="tabs">
					<Link to="/game/blockparty">Описание</Link>
					<button className="selected">Музыка</button>
				</div>
				<div className="info-text">
					<div className="player">
						<div className="player-body">
							<div className="player-left">
								<h1>{this.state.status}</h1>
								<p>{this.state.statusDesc}</p>
								<h2>Громкость:</h2>
								<input
										type="range"
										min="1"
										max="10000"
										value={this.state.volume * 10000}
										className="player-volume" id="volume"
										onChange={e => {
											const vol = (e.target as any).value / 10000;
											this.start.volume = vol;
											this.end.volume = vol;
											
											if (this.audio !== null) {
												this.audio.volume = vol;
											}

											this.setState({volume: vol});
										}}
								/>
							</div>
							<div className="player-block-image">
								<img src={"/jukebox/images/" + this.state.color + ".png"} alt={this.state.color}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>;
	}

}

export default Player;