import querystring from 'query-string';
import React, {Component} from 'react';
import API from '../api/API';
import {browserHistory} from '../MainWrapper';
import Loader from '../util/Loader';
import './PaymentStatusPage.css';

class PaymentStatusErrorPage extends Component {

	state = {
		error: false
	}
	
	private id = 0;

	componentWillMount() {
		const id = PaymentStatusErrorPage.getPaymentId();

		if (id === null || Number.isNaN(+id)) {
			browserHistory.push('/');

			return;
		}

		this.id = +id;
	}

	render() {
		if (this.state.error) {
			return <div className="paymentStatusPage">
				<h1>Не удалось получить статус платежа</h1>

				<p>Номер платежа: <b>{this.id}</b></p>
			</div>;
		}
		
		return <Loader method={c => API.getPaymentStatus(this.id, c)} children={(data: {completed: boolean, paymentErrorMessage: string | null}) => {
			if (data.paymentErrorMessage !== null) {
				return <div className="paymentStatusPage">
					<h1>Ой, кажется коты снова запутали клубок!</h1>

					<p>К сожалению, во время обработки платежа <b>{this.id}</b> произошла ошибка:</p>

					<p><b>{data.paymentErrorMessage}</b><br/></p>

					<button onClick={() => browserHistory.push('/')}>Вернуться в магазин</button>
				</div>;
			}
			
			if (data.completed) {
				return <div className="paymentStatusPage">
					<h1>Ты — лучший!</h1>

					<p><b>Спасибо большое за покупку!</b> Ты помог серверу жить и развиваться дальше!<br/>
						Эти деньги пойдут на развитие новых режимов и поддержку старых, а также<br/>
						на оплату оборудования и работы сотрудников.</p>

					<p>Номер платежа: <b>{this.id}</b></p>

					<p><b>Покупка будет выдана в течении 2 минут онлайна на сервере.</b><br/>
						Если покупка не пришла в течении 30 минут, напиши нам сюда: <a href="https://vk.me/prostocraft" target="_blank">vk.me/prostocraft</a>,<br/>
						указав свой ник и номер платежа или способ оплаты.</p>

					<button onClick={() => browserHistory.push('/')}>Вернуться в магазин</button>
				</div>;
			}

			return <div className="paymentStatusPage">
				<h1>Платёж всё ещё обрабатывается</h1>

				<p>Номер платежа: <b>{this.id}</b></p>
			</div>;
		}} onError={() => this.setState({error: true})}/>;
	}

	private static getPaymentId(): string | null {
		if (window.location.search !== '') {
			const parsed = querystring.parse(window.location.search.substring(1));

			if (typeof parsed.account === 'string') {
				return parsed.account;
			}
		}

		return null;
	}

}

export default PaymentStatusErrorPage;