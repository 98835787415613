import React from 'react';

import API from './api/API';

import { Link } from 'react-router-dom';
import Loader from './util/Loader';

import './GameListPage.css';

const GameListPage = () => <Loader method={API.getGames} children={(data, _) => {
	const list = (data as string[]).map(id => <Link key={id} to={"/game/" + id} className="game">
		<img src={"/image/game/" + id + ".png"}/>
	</Link>);

	return <div className="game-list">{list}</div>;
}}/>;

export default GameListPage;