import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';

import Main from './Main';
import MobileMain from './mobile/MobileMain';
import MainWrapper from './MainWrapper';

import './reset.css';
import './index.css';

Modal.setAppElement(document.getElementById('root'));

let main;

if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
	main = <MobileMain/>;
} else {
	main = <Main/>;
}

ReactDOM.render(<MainWrapper>{main}</MainWrapper>, document.getElementById('root'));