import React from 'react';

import TopControls from './TopControls';
import TopMenu from './TopMenu';

import './Header.css';

const Header = () => <header>
	<div className="header-content">
		<div className="logo">
			<img src="/logo.png"/>
		</div>
		<div className="header-right">
			<div className="header-top">
				<TopControls/>
			</div>
			<div className="header-menu">
				<TopMenu/>
			</div>
		</div>
	</div>
</header>;

export default Header;