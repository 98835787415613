import React, { Component } from "react";

import { APICallback } from "../api/API";

interface LoaderProps {

	methods: Array<(callback: APICallback<any>) => void>;
	children: (data: any[], reload: () => void) => any;

}

class MultiLoader extends Component<LoaderProps> {

	state: {
		errors: string[],
		data: any[]
	} = {
		errors: this.props.methods.map(() => ''),
		data: this.props.methods.map(() => null)
	}

	componentDidMount() {
		this.callMethods();
	}

	render() {
		if (this.state.data.findIndex(e => e === null) !== -1) {
			return <div style={{padding: '20px'}}>Идёт загрузка...</div>;
		}

		if (this.state.errors.findIndex(e => e === null) !== -1) {
			return <div style={{padding: '20px'}}>{this.state.errors.filter(e => e !== '').join(', ')}</div>;
		}

		return this.props.children(this.state.data, () => this.callMethods());
	}

	private callMethods() {
		this.props.methods.forEach((m, i) => {
			m(response => {
				if (response.isError()) {
					const errors = [...this.state.errors];
					errors[i] = 'Произошла ошибка при загрузке, обновите страницу';
					this.setState({errors: errors});
				} else {
					const data = [...this.state.data];
					data[i] = response.response!;
					this.setState({data: data});
				}
			});
		});
	}

}

export default MultiLoader;