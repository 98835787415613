import React from 'react';

import './RulesPage.css';

const OfferAgreementPage = () => <div className="rules">
	<h2>ДОГОВОР-ОФЕРТА</h2>

	<h2>1. Общие положения</h2>
	<p><b>1.1.</b> Настоящий Договор является официальным предложением (публичной офертой) ИП Калинин Илья Сергеевич (в дальнейшем «Исполнитель») для полностью дееспособного физического
		(далее – «Заказчик»), которое примет настоящее предложение, на указанных ниже условиях.</p>
	<p><b>1.2.</b> В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК РФ), в случае принятия изложенных ниже условий и оплаты услуг юридическое или
		физическое лицо, производящее акцепт этой оферты, становится Заказчиком (в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению Договора на условиях,
		изложенных в оферте).</p>
	<p><b>1.3.</b> Моментом полного и безоговорочного принятия Заказчиком предложения Исполнителя заключить Договор оферты (акцептом оферты) считается факт подтверждения готовности совершить
		оплату услуги Исполнителя, посредством нажатия кнопки «Купить» в «корзине» или на страницах сайта https://prostocraft.ru/.</p>
	<p><b>1.4.</b> Осуществляя акцепт Договора в порядке, определенном п. 1.3 Договора, Заказчик подтверждает, что он ознакомлен, согласен, полностью и безоговорочно принимает все условия
		Договора в том виде, в каком они изложены в тексте Договора, в том числе в приложениях к Договору, являющихся его неотъемлемой частью.</p>
	<p><b>1.5.</b> Клиент согласен, что акцепт Договора в порядке, указанном в п. 1.2 Договора является заключением Договора на условиях, изложенных в нем.</p>
	<p><b>1.6.</b> Договор не может быть отозван.</p>
	<p><b>1.7.</b> Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем (далее по тексту - Стороны) и сохраняет при этом юридическую силу.</p>

	<h2>2. Предмет договора</h2>
	<p><b>2.1.</b> Предметом настоящего Договора является возмездное оказание Исполнителем услуг в соответствии с условиями настоящего Договора.</p>
	<p><b>2.2.</b> Заказчик полностью принимает условия Договора и оплачивает услуги Исполнителя в соответствии с условиями настоящего Договора.</p>

	<h2>3. Оплата Услуг</h2>
	<p><b>3.1.</b> Стоимость услуг по Договору определяется в соответствии с действующими ценами и прописана на сайте https://prostocraft.ru/</p>
	<p><b>3.2.</b> Стоимость услуги может быть изменена Исполнителем в одностороннем порядке.</p>
	<p><b>3.3.</b> Способы оплаты услуги указаны при оформлении платежа.</p>

	<h2>4. Интеллектуальная собственность</h2>
	<p><b>4.1.</b> Вся текстовая информация и графические изображения, находящиеся на сайте https://prostocraft.ru/ являются собственностью Исполнителя.</p>

	<h2>5. Особые условия и ответственность сторон.</h2>
	<p><b>5.1.</b> Исполнитель несет ответственность за своевременность предоставляемых услуг при выполнении Заказчиком установленных требований и правил, размещенных на сайте
		https://prostocraft.ru/</p>
	<p><b>5.2.</b> Исполнитель освобождается от ответственности за нарушение условий Договора, если такое нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая:
		действия органов государственной власти, пожар, наводнение, землетрясение, другие стихийные действия, отсутствие электроэнергии, забастовки, гражданские волнения, беспорядки, любые
		иные обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на выполнение Исполнителем Договора.</p>
	<p><b>5.3.</b> Исполнитель не несет ответственности за качество каналов связи общего пользования или служб, предоставляющих доступ Заказчика к его услугам.</p>

	<h2>6. Конфиденциальность и защита персональной информации</h2>
	<p><b>6.1.</b> Исполнитель обязуется не разглашать полученную от Заказчика информацию.</p>
	<p><b>6.2.</b> Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона.</p>
	<p><b>6.3.</b> Исполнитель не несет ответственности за сведения, предоставленные Заказчиком на сайте https://prostocraft.ru/ в общедоступной форме.</p>

	<h2>7. Порядок рассмотрения претензий и споров</h2>
	<p><b>7.1.</b> Претензии Заказчика по предоставляемым услугам принимаются Исполнителем к рассмотрению по электронной почте в течение 3 (рабочих) дней с момента возникновения спорной
		ситуации.</p>
	<p><b>7.2.</b> При рассмотрении спорных ситуаций Исполнитель вправе запросить у Заказчика всю интересующую документацию относительно рассматриваемого случая. В случае непредоставления
		Заказчиком документов в течение 1 рабочего дня после дня требования, претензия рассмотрению Исполнителем не подлежит.</p>
	<p><b>7.3.</b> Исполнитель и Заказчик, принимая во внимания характер оказываемой услуги, обязуются в случае возникновения споров и разногласий, связанных с оказанием услуг, применять
		досудебный порядок урегулирования спора. В случае невозможности урегулирования спора в досудебном порядке стороны вправе обратиться в суд.</p>

	<h2>8. Прочие условия</h2>
	<p><b>8.1.</b> Заказчик обладает всеми правами и полномочиями, необходимыми для заключения и исполнения Договора.</p>
	<p><b>8.2.</b> Заказчик вправе в любое время в одностороннем порядке отказаться от услуг Исполнителя. В случае одностороннего отказа Заказчика от услуг Исполнителя произведенная оплата
		не возвращается.</p>
	<p><b>8.3.</b> Исполнитель оставляет за собой право изменять или дополнять любые из условий настоящего Договора в любое время, опубликовывая все изменения на своем сайте.</p>
	<p><b>8.4.</b> Договор считается совершенным и исполненным со стороны Исполнителя и Пользователя, в случае успешного получения Пользователем услуги на сервере проекта (ip
		mc.prostocraft.ru). В случае неисполнения договора Исполнителем, если с момента оплаты услуги или услуг прошло не более 14 дней, Пользователь имеет право запросить возврат денежных средств или
		замену услуги или услуг на сайте на сумму не превышающую оплату за первоначальные услугу или услуги.</p>
	<p><b>8.5.</b> По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются действующим законодательством Российской Федерации.</p>
	<p><b>8.6.</b> Признание судом недействительности какого-либо положения настоящего Договора и правил не влечет за собой недействительность остальных положений.</p>

	<h2>9. Реквизиты Исполнителя</h2>
	<p>ИП Калинин Илья Сергеевич</p>
	<p>ИНН 272210678597</p>
	<p>ОРГНИП 321272400006253</p>

	<h2>10. Контактные данные</h2>
	<p>Электронная почта: help@prostocraft.ru</p>
</div>;

export default OfferAgreementPage;