import React, { Component } from 'react';

import './TopControls.css';
import API from './api/API';
import appContext from './context/AppContext';
import { Cart } from './cart/Cart';
import { copyTextToClipboard } from './util/Clipboard';

class TopControls extends Component {

	state = {online: -1} 

	task: number = -1;

	listener = () => this.setState({});

	componentDidMount() {
		this.updateOnline();

		this.task = setInterval(() => this.updateOnline(), 120000) as any;

		appContext.addCartChangeListener(this.listener);
	}

	updateOnline() {
		this.setState({online: 0})
		// API.getOnline(response => {
		// 	if (response.response !== undefined) {
		// 		this.setState({online: response.response!.online})
		// 	}
		// });
	}

	componentWillUnmount() {
		clearInterval(this.task);

		appContext.removeCartChangeListener(this.listener);
	}

	render() {
		const count = Cart.getItems().length;

		return <div className="top-controls">
			<button className="top-control cart" onClick={() => appContext.setCartOpened(true)}>{count === 0 ? null : <span>{count}</span>}</button>
		
			<div className="top-control online">
				<div className="domain" onClick={() => copyTextToClipboard('mc.prostocraft.ru')}>
					mc.prostocraft.ru
				</div>
				<div className="counter">
					{this.state.online === -1 ? '...' : this.state.online}
				</div>
			</div>
		</div>;
	}

}

export default TopControls;