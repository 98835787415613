import React, {Component} from 'react';

import {AbstractProduct, getProductData} from '../api/API';
import {millisToString} from '../util/TimeUtil';
import appContext from '../context/AppContext';

import {DataContextConsumer} from '../data/DataContext';
import PriceCalculator from '../common/PriceCalculator';
import {Cart} from '../cart/Cart';

import './Modal.css';
import './PurchaseModal.css';

type State = {
	setting?: number
}

class PurchaseModal extends Component<{product: AbstractProduct}> {
	
	state: State = {
		setting: undefined
	}

	render() {
		const {settings, setting, isDuration} = getProductData(this.props.product, this.state.setting);

		// Если настройка только одна, то выбирать по сути и нечего
		const displaySettings = settings.length > 1;

		return <DataContextConsumer children={(ctx) => {
			return <PriceCalculator playerName={''} promoCode={''} items={[this.getCartItem()]} products={ctx.products} children={price => {
				return <div className={'modal purchase-modal' + (displaySettings ? '' : ' no-settings')}>
					<button className="close" onClick={() => appContext.setOpenedProduct(undefined)}/>

					<h1>{this.props.product.name}</h1>

					<hr/>

					<div className="first">
						{displaySettings ? <div>
							{settings.map(d => <button key={d} className={d === setting ? "setting active" : "setting"} onClick={() => {

								this.setState({setting: d});

							}}>{isDuration ? millisToString(d) : d}</button>)}
						</div> : null}

						<button className="continue" onClick={() => this.onClick()}>Далее ({price} ₽)</button>
					</div>

					<hr/>

					<div className="desc">{this.props.product.fullDescription
						.split("\n")
						.map(line => line.replace('{setting}', '' + setting))
						.map((line, i) => <p key={i}>{line}</p>)
					}</div>
				</div>;
			}}/>;
		}}/>;
	}

	private getCartItem() {
		const item = Cart.createCartItem(this.props.product);
		item.setting = this.state.setting;
		item.multiplier = 1;
		return item;
	}

	private onClick() {
		appContext.setOpenedProduct(undefined);
		
		appContext.setPurchasedProducts([this.getCartItem()]);
	}

}

export default PurchaseModal;