import React, {Component} from 'react';
import './GiftCheckbox.css';

type Props = {checked: boolean, onChange: (checked: boolean) => void};

class GiftCheckbox extends Component<Props> {

	render() {
		return <div className="gift-checkbox">
			<input
				type="checkbox"
				id="gift-checkbox"
				className="gift-checkbox"
				defaultChecked={this.props.checked}
				onChange={e => this.props.onChange(e.target.checked)}
			/>

			<label htmlFor="gift-checkbox">Это подарок другому игроку</label>
		</div>;
	}

}

export default GiftCheckbox;
