import React from 'react';

import { Link } from 'react-router-dom';

import API, { TopPlayer, Game, APICallback } from './api/API';

import TopEntry from './TopEntry';
import Loader from './util/Loader';

import './GamePage.css';

function parseLinks(s: string) {
	const re = /((https?:\/\/)?(\w+\.)*\w+\.\w{2,6}(\/[^ ]*)?)/g;

	let match: any = null;

	let i = 0;

	const list: any[] = [];

	while ((match = re.exec(s)) !== null) {
		if (match.index > i) {
			list.push(<span key={i}>{s.substring(i, match.index)}</span>);
		}

		const link = s.substring(match.index, match.index + match[0].length);

		list.push(<a key={match.index} href={link.indexOf('://') !== -1 ? link : 'https://' + link}>{link}</a>);

		i = match.index + match[0].length;
	}

	if (i < s.length - 1) {
		list.push(<span key={i}>{s.substring(i)}</span>);
	}

	return list;
}

function textToComponents(s: string) {
	return s.split("\n").map((line, i) => <p key={i}>{parseLinks(line)}</p>);
}

const GamePage =  (props: {gameId: string}) => <div>
	<Loader method={(c: APICallback<Game>) => API.getGame(props.gameId, c)} children={(data, _) => {
		return <div className="game-page">
			<Link className="back-to-games" to="/game"/>

			<div className="info">
				<div className="tabs">
					<button className="selected">Описание</button>
					{props.gameId === 'blockparty' ? <Link to="/game/blockparty/jukebox">Музыка</Link> : null}
				</div>
				<div className="info-text">
					<h1>{data.name}</h1>
					<div>{textToComponents(data.description)}</div>
				</div>
			</div>

			<div className="top">
				<div className="header">Топ игроков</div>

				<Loader method={(c: APICallback<TopPlayer[]>) => API.getTopPlayers(props.gameId, c)} children={(top, _) => {
					const entries = top.map((e, i) => <TopEntry key={i} name={e.name} place={i + 1} value={e.value} valueName={data.topColumnDisplayName} headImage={e.headImage}/>);

					return <div>{entries}</div>;
				}}/>
			</div>
		</div>;
	}}/>
</div>;

export default GamePage;