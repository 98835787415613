import React from 'react';

import { NavLink } from 'react-router-dom';

import './TopMenu.css';

function style(name: string, padding: number) {
	return {backgroundImage: "url('/" + name + ".png')", paddingLeft: padding + 'px'};
}

const TopMenu = () => <ul className="top-menu">
	<li style={style('shop', 25)}><NavLink activeClassName="active" to="/shop" isActive={(match, loc) => !!match || loc.pathname ==='/'}>Магазин</NavLink></li>
	<li style={style('games', 23)}><NavLink activeClassName="active" to="/game">Наши игры</NavLink></li>
	<li style={style('rules', 21)}><NavLink activeClassName="active" to="/rules">Правила</NavLink></li>
	<li style={style('help', 30)}><a href="https://vk.me/prostocraft" target="blank">Помощь</a></li>
	<li style={style('vk', 26)}><a href="https://vk.com/prostocraft" target="blank">Группа ВК</a></li>
</ul>;

export default TopMenu;